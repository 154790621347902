<script setup>
import { ref, reactive, watch, computed, onBeforeMount, onMounted } from "vue";
import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/vue/solid";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();

import SumJson from "../components/SumJson.vue";

const store = useStore();
const shud = computed(() => store.state.station_huds);

function myStation() {
    return store.state.station_huds[route.params.id];
}

function taskDelta(task) {
    if (task.state == "finished2") {
        return task.endWeight - task.targetWeight + "g";
    }
}

function niceWeight(task, field) {
    let state = myTask().state;
    console.log("nw", task, state);
    if (state == "start1") {
        if (field == "start") {
            return "checking";
        } else {
            return "--";
        }
    }
    if (state == "finished1") {
        if (field == "start") {
            return task.startWeight + "g";
        } else if (field == "target") {
            return task.targetWeight + "g";
        } else {
            return "--";
        }
    }
    if (state == "start2") {
        if (field == "start") {
            return task.startWeight + "g";
        } else if (field == "target") {
            return task.targetWeight + "g";
        } else {
            return "checking"
        }
    }
    if (state == "finished2") {
        if (field == "start") {
            return task.startWeight + "g";
        } else if (field == "target") {
            return task.targetWeight + "g";
        } else if (field == "end") {
            return task.endWeight + "g";
        } else {
            return "--";
        }
    }
    return "?task.state?";
}
function taskDeltaType(task) {
    if (task.state == "finished2") {
        if (task.endWeight == task.targetWeight) {
            return "exact";
        }
        if (task.endWeight < task.targetWeight) {
            return "under";
        }
        if (task.endWeight > task.targetWeight) {
            return "over";
        }
    } else {
        return "";
    }
}

function bgColor2(task, field) {
    if (task.state == "start1") {
        if (field == "start") {
            return "bg-yellow-100";
        } else {
            return "bg-gray-200";
        }
    }
    if (task.state == "finished1") {
        if (field == "start") {
            return "bg-green-100";
        }
        if (field == "target") {
            return "bg-green-100";
        } else {
            return "bg-gray-100";
        }
    }
    if (task.state == "start2") {
        if (field == "start") {
            return "bg-green-50";
        }
        if (field == "target") {
            return "bg-green-50";
        } else {
            return "bg-yellow-200";
        }
    }
    if (task.state == "finished2") {
        if (field == "start") {
            return "bg-green-50";
        }
        if (field == "target") {
            return "bg-green-50";
        } else {
            return "bg-green-100";
        }
    }
}

function title() {
    if (myStation()) {
        return myStation().name + " Monitor";
    } else {
        return "Real-Time Monitor - Connecting";
    }
}
function guidance() {
    if (myStation()) {
        return myStation().guidance;
    } else {
        return "No Guidance";
    }
}
function myTask() {
    if (myStation()) {
        return myStation().task;
    } else {
        return undefined;
    }
}
function subtitle() {
    let t = myTask();
    if (t) {
        return "Employee - " + t.employee.name + " - #" + t.employee.id;
    } else {
        return "";
    }
}
function startWeight() {
    let t = myTask();
    if (t) {
        console.log("sw", t);
        return t.startWeight;
    } else {
        return 0;
    }
}

function taskReady() {
  return myTask() && myTask().state != 'undefined'
}

</script>

<template>
  <h1 class="flex justify-center text-3xl font-bold underline">
      {{ title() }}
  </h1>
  <div v-if="!taskReady()" class="mt-4">
    <h3 class="px-4 py-4 my-2 rounded-md border-2 border-grey-300 text-lg leading-6 font-medium text-gray-900 text-center">
          Scan to Start
    </h3>
  </div>
  <div v-if="taskReady()" class="mt-4">
    <h3 class="px-4 py-4 my-2 rounded-md border-2 border-grey-300 text-4xl leading-6 font-medium text-gray-900 text-center">
        <b>{{ subtitle() }}</b>
    </h3>
    <dl class="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
      <div :class="[bgColor2(myTask(), 'start'),'px-4 py-5 sm:p-6']">
        <dt class="text-base font-semibold text-gray-900">
          Before Processing
        </dt>
        <dd class="mt-1 flex justify-between items-baseline md:block lg:flex">
          <div class="flex items-baseline text-2xl font-semibold text-indigo-600">
            {{ niceWeight(myTask(), "start") }}
          </div>
        </dd>
      </div>
      <div :class="[bgColor2(myTask(), 'target'),'px-4 py-5 sm:p-6']">
        <dt class="text-base font-semibold text-gray-900">
          Target
        </dt>
        <dd class="mt-1 flex justify-between items-baseline md:block lg:flex">
          <div class="flex items-baseline text-2xl font-semibold text-indigo-600">
            {{ niceWeight(myTask(), "target") }}
          </div>
        </dd>
      </div>
      <div :class="[bgColor2(myTask(), 'end'),'px-4 py-5 sm:p-6']">
        <dt class="text-base font-semibold text-gray-900">
          After Processing
        </dt>
        <dd class="mt-1 flex justify-between items-baseline md:block lg:flex">
          <div class="flex items-baseline text-2xl font-semibold text-indigo-600">
            {{ niceWeight(myTask(), "end") }}
          </div>

          <div v-if="taskDeltaType(myTask()) == 'over'" :class="['bg-green-200 text-green-800', 'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0']">
            <ArrowSmUpIcon class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500" aria-hidden="true" />
            <span class="sr-only"> Over by </span>
            {{ taskDelta(myTask()) }}
          </div>

          <div v-if="taskDeltaType(myTask()) == 'under'" :class="['bg-red-100 text-red-800', 'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0']">
            <ArrowSmDownIcon class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500" aria-hidden="true" />
            <span class="sr-only"> Under by </span>
            {{ taskDelta(myTask()) }}
          </div>
        </dd>
      </div>
    </dl>
  </div>
  <div>
      <div class="grid grid-cols-3 gap-4 w-full mt-10" v-if="myStation()?.code == 'precheck'">
          <div class='p-4'><img src='/img/fish_pre.jpg'></div>
          <div class='p-4'><img src='/img/chicken_pre.png'></div>
          <div class='p-4'><img src='/img/shrimp_pre.jpg'></div>
      </div>
      <div class="grid grid-cols-3 gap-4 w-full mt-10" v-if="myStation()?.code == 'postcheck'">
          <div class='p-4'><img src='/img/fish_post.webp'></div>
          <div class='p-4'><img src='/img/chicken_post.png'></div>
          <div class='p-4'><img src='/img/shrimp_post.png'></div>
      </div>
  </div>
  <div class='text-white hidden text-xs'>
      Info: {{guidance()}}
  </div>
  <div class='text-white hidden text-xs'>
      Station: {{myStation()}}
  </div>

</template>

<style>
</style>
