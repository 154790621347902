/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const hudvue = $root.hudvue = (() => {

    /**
     * Namespace hudvue.
     * @exports hudvue
     * @namespace
     */
    const hudvue = {};

    hudvue.v1 = (function() {

        /**
         * Namespace v1.
         * @memberof hudvue
         * @namespace
         */
        const v1 = {};

        v1.Person = (function() {

            /**
             * Properties of a Person.
             * @memberof hudvue.v1
             * @interface IPerson
             * @property {number|null} [id] Person id
             * @property {string|null} [name] Person name
             * @property {hudvue.v1.ICard|null} [card] Person card
             */

            /**
             * Constructs a new Person.
             * @memberof hudvue.v1
             * @classdesc Represents a Person.
             * @implements IPerson
             * @constructor
             * @param {hudvue.v1.IPerson=} [properties] Properties to set
             */
            function Person(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Person id.
             * @member {number} id
             * @memberof hudvue.v1.Person
             * @instance
             */
            Person.prototype.id = 0;

            /**
             * Person name.
             * @member {string} name
             * @memberof hudvue.v1.Person
             * @instance
             */
            Person.prototype.name = "";

            /**
             * Person card.
             * @member {hudvue.v1.ICard|null|undefined} card
             * @memberof hudvue.v1.Person
             * @instance
             */
            Person.prototype.card = null;

            /**
             * Creates a new Person instance using the specified properties.
             * @function create
             * @memberof hudvue.v1.Person
             * @static
             * @param {hudvue.v1.IPerson=} [properties] Properties to set
             * @returns {hudvue.v1.Person} Person instance
             */
            Person.create = function create(properties) {
                return new Person(properties);
            };

            /**
             * Encodes the specified Person message. Does not implicitly {@link hudvue.v1.Person.verify|verify} messages.
             * @function encode
             * @memberof hudvue.v1.Person
             * @static
             * @param {hudvue.v1.IPerson} message Person message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Person.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.card != null && Object.hasOwnProperty.call(message, "card"))
                    $root.hudvue.v1.Card.encode(message.card, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified Person message, length delimited. Does not implicitly {@link hudvue.v1.Person.verify|verify} messages.
             * @function encodeDelimited
             * @memberof hudvue.v1.Person
             * @static
             * @param {hudvue.v1.IPerson} message Person message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Person.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Person message from the specified reader or buffer.
             * @function decode
             * @memberof hudvue.v1.Person
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {hudvue.v1.Person} Person
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Person.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hudvue.v1.Person();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    case 10: {
                            message.card = $root.hudvue.v1.Card.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Person message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof hudvue.v1.Person
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {hudvue.v1.Person} Person
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Person.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Person message.
             * @function verify
             * @memberof hudvue.v1.Person
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Person.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.card != null && message.hasOwnProperty("card")) {
                    let error = $root.hudvue.v1.Card.verify(message.card);
                    if (error)
                        return "card." + error;
                }
                return null;
            };

            /**
             * Creates a Person message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof hudvue.v1.Person
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {hudvue.v1.Person} Person
             */
            Person.fromObject = function fromObject(object) {
                if (object instanceof $root.hudvue.v1.Person)
                    return object;
                let message = new $root.hudvue.v1.Person();
                if (object.id != null)
                    message.id = object.id >>> 0;
                if (object.name != null)
                    message.name = String(object.name);
                if (object.card != null) {
                    if (typeof object.card !== "object")
                        throw TypeError(".hudvue.v1.Person.card: object expected");
                    message.card = $root.hudvue.v1.Card.fromObject(object.card);
                }
                return message;
            };

            /**
             * Creates a plain object from a Person message. Also converts values to other types if specified.
             * @function toObject
             * @memberof hudvue.v1.Person
             * @static
             * @param {hudvue.v1.Person} message Person
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Person.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.id = 0;
                    object.name = "";
                    object.card = null;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.card != null && message.hasOwnProperty("card"))
                    object.card = $root.hudvue.v1.Card.toObject(message.card, options);
                return object;
            };

            /**
             * Converts this Person to JSON.
             * @function toJSON
             * @memberof hudvue.v1.Person
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Person.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Person
             * @function getTypeUrl
             * @memberof hudvue.v1.Person
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Person.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/hudvue.v1.Person";
            };

            return Person;
        })();

        v1.Card = (function() {

            /**
             * Properties of a Card.
             * @memberof hudvue.v1
             * @interface ICard
             * @property {number|null} [id] Card id
             * @property {Uint8Array|null} [uid] Card uid
             */

            /**
             * Constructs a new Card.
             * @memberof hudvue.v1
             * @classdesc Represents a Card.
             * @implements ICard
             * @constructor
             * @param {hudvue.v1.ICard=} [properties] Properties to set
             */
            function Card(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Card id.
             * @member {number} id
             * @memberof hudvue.v1.Card
             * @instance
             */
            Card.prototype.id = 0;

            /**
             * Card uid.
             * @member {Uint8Array} uid
             * @memberof hudvue.v1.Card
             * @instance
             */
            Card.prototype.uid = $util.newBuffer([]);

            /**
             * Creates a new Card instance using the specified properties.
             * @function create
             * @memberof hudvue.v1.Card
             * @static
             * @param {hudvue.v1.ICard=} [properties] Properties to set
             * @returns {hudvue.v1.Card} Card instance
             */
            Card.create = function create(properties) {
                return new Card(properties);
            };

            /**
             * Encodes the specified Card message. Does not implicitly {@link hudvue.v1.Card.verify|verify} messages.
             * @function encode
             * @memberof hudvue.v1.Card
             * @static
             * @param {hudvue.v1.ICard} message Card message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Card.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                    writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.uid);
                return writer;
            };

            /**
             * Encodes the specified Card message, length delimited. Does not implicitly {@link hudvue.v1.Card.verify|verify} messages.
             * @function encodeDelimited
             * @memberof hudvue.v1.Card
             * @static
             * @param {hudvue.v1.ICard} message Card message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Card.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Card message from the specified reader or buffer.
             * @function decode
             * @memberof hudvue.v1.Card
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {hudvue.v1.Card} Card
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Card.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hudvue.v1.Card();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.uid = reader.bytes();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Card message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof hudvue.v1.Card
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {hudvue.v1.Card} Card
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Card.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Card message.
             * @function verify
             * @memberof hudvue.v1.Card
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Card.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.uid != null && message.hasOwnProperty("uid"))
                    if (!(message.uid && typeof message.uid.length === "number" || $util.isString(message.uid)))
                        return "uid: buffer expected";
                return null;
            };

            /**
             * Creates a Card message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof hudvue.v1.Card
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {hudvue.v1.Card} Card
             */
            Card.fromObject = function fromObject(object) {
                if (object instanceof $root.hudvue.v1.Card)
                    return object;
                let message = new $root.hudvue.v1.Card();
                if (object.id != null)
                    message.id = object.id >>> 0;
                if (object.uid != null)
                    if (typeof object.uid === "string")
                        $util.base64.decode(object.uid, message.uid = $util.newBuffer($util.base64.length(object.uid)), 0);
                    else if (object.uid.length >= 0)
                        message.uid = object.uid;
                return message;
            };

            /**
             * Creates a plain object from a Card message. Also converts values to other types if specified.
             * @function toObject
             * @memberof hudvue.v1.Card
             * @static
             * @param {hudvue.v1.Card} message Card
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Card.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.id = 0;
                    if (options.bytes === String)
                        object.uid = "";
                    else {
                        object.uid = [];
                        if (options.bytes !== Array)
                            object.uid = $util.newBuffer(object.uid);
                    }
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.uid != null && message.hasOwnProperty("uid"))
                    object.uid = options.bytes === String ? $util.base64.encode(message.uid, 0, message.uid.length) : options.bytes === Array ? Array.prototype.slice.call(message.uid) : message.uid;
                return object;
            };

            /**
             * Converts this Card to JSON.
             * @function toJSON
             * @memberof hudvue.v1.Card
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Card.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Card
             * @function getTypeUrl
             * @memberof hudvue.v1.Card
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Card.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/hudvue.v1.Card";
            };

            return Card;
        })();

        v1.Tray = (function() {

            /**
             * Properties of a Tray.
             * @memberof hudvue.v1
             * @interface ITray
             * @property {number|null} [id] Tray id
             * @property {hudvue.v1.ICard|null} [card] Tray card
             */

            /**
             * Constructs a new Tray.
             * @memberof hudvue.v1
             * @classdesc Represents a Tray.
             * @implements ITray
             * @constructor
             * @param {hudvue.v1.ITray=} [properties] Properties to set
             */
            function Tray(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Tray id.
             * @member {number} id
             * @memberof hudvue.v1.Tray
             * @instance
             */
            Tray.prototype.id = 0;

            /**
             * Tray card.
             * @member {hudvue.v1.ICard|null|undefined} card
             * @memberof hudvue.v1.Tray
             * @instance
             */
            Tray.prototype.card = null;

            /**
             * Creates a new Tray instance using the specified properties.
             * @function create
             * @memberof hudvue.v1.Tray
             * @static
             * @param {hudvue.v1.ITray=} [properties] Properties to set
             * @returns {hudvue.v1.Tray} Tray instance
             */
            Tray.create = function create(properties) {
                return new Tray(properties);
            };

            /**
             * Encodes the specified Tray message. Does not implicitly {@link hudvue.v1.Tray.verify|verify} messages.
             * @function encode
             * @memberof hudvue.v1.Tray
             * @static
             * @param {hudvue.v1.ITray} message Tray message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Tray.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                if (message.card != null && Object.hasOwnProperty.call(message, "card"))
                    $root.hudvue.v1.Card.encode(message.card, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified Tray message, length delimited. Does not implicitly {@link hudvue.v1.Tray.verify|verify} messages.
             * @function encodeDelimited
             * @memberof hudvue.v1.Tray
             * @static
             * @param {hudvue.v1.ITray} message Tray message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Tray.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Tray message from the specified reader or buffer.
             * @function decode
             * @memberof hudvue.v1.Tray
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {hudvue.v1.Tray} Tray
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Tray.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hudvue.v1.Tray();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.uint32();
                            break;
                        }
                    case 10: {
                            message.card = $root.hudvue.v1.Card.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Tray message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof hudvue.v1.Tray
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {hudvue.v1.Tray} Tray
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Tray.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Tray message.
             * @function verify
             * @memberof hudvue.v1.Tray
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Tray.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.card != null && message.hasOwnProperty("card")) {
                    let error = $root.hudvue.v1.Card.verify(message.card);
                    if (error)
                        return "card." + error;
                }
                return null;
            };

            /**
             * Creates a Tray message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof hudvue.v1.Tray
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {hudvue.v1.Tray} Tray
             */
            Tray.fromObject = function fromObject(object) {
                if (object instanceof $root.hudvue.v1.Tray)
                    return object;
                let message = new $root.hudvue.v1.Tray();
                if (object.id != null)
                    message.id = object.id >>> 0;
                if (object.card != null) {
                    if (typeof object.card !== "object")
                        throw TypeError(".hudvue.v1.Tray.card: object expected");
                    message.card = $root.hudvue.v1.Card.fromObject(object.card);
                }
                return message;
            };

            /**
             * Creates a plain object from a Tray message. Also converts values to other types if specified.
             * @function toObject
             * @memberof hudvue.v1.Tray
             * @static
             * @param {hudvue.v1.Tray} message Tray
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Tray.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.id = 0;
                    object.card = null;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.card != null && message.hasOwnProperty("card"))
                    object.card = $root.hudvue.v1.Card.toObject(message.card, options);
                return object;
            };

            /**
             * Converts this Tray to JSON.
             * @function toJSON
             * @memberof hudvue.v1.Tray
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Tray.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Tray
             * @function getTypeUrl
             * @memberof hudvue.v1.Tray
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Tray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/hudvue.v1.Tray";
            };

            return Tray;
        })();

        v1.Cut = (function() {

            /**
             * Properties of a Cut.
             * @memberof hudvue.v1
             * @interface ICut
             * @property {number|null} [id] Cut id
             * @property {string|null} [name] Cut name
             */

            /**
             * Constructs a new Cut.
             * @memberof hudvue.v1
             * @classdesc Represents a Cut.
             * @implements ICut
             * @constructor
             * @param {hudvue.v1.ICut=} [properties] Properties to set
             */
            function Cut(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Cut id.
             * @member {number} id
             * @memberof hudvue.v1.Cut
             * @instance
             */
            Cut.prototype.id = 0;

            /**
             * Cut name.
             * @member {string} name
             * @memberof hudvue.v1.Cut
             * @instance
             */
            Cut.prototype.name = "";

            /**
             * Creates a new Cut instance using the specified properties.
             * @function create
             * @memberof hudvue.v1.Cut
             * @static
             * @param {hudvue.v1.ICut=} [properties] Properties to set
             * @returns {hudvue.v1.Cut} Cut instance
             */
            Cut.create = function create(properties) {
                return new Cut(properties);
            };

            /**
             * Encodes the specified Cut message. Does not implicitly {@link hudvue.v1.Cut.verify|verify} messages.
             * @function encode
             * @memberof hudvue.v1.Cut
             * @static
             * @param {hudvue.v1.ICut} message Cut message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Cut.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                return writer;
            };

            /**
             * Encodes the specified Cut message, length delimited. Does not implicitly {@link hudvue.v1.Cut.verify|verify} messages.
             * @function encodeDelimited
             * @memberof hudvue.v1.Cut
             * @static
             * @param {hudvue.v1.ICut} message Cut message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Cut.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Cut message from the specified reader or buffer.
             * @function decode
             * @memberof hudvue.v1.Cut
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {hudvue.v1.Cut} Cut
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Cut.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hudvue.v1.Cut();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Cut message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof hudvue.v1.Cut
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {hudvue.v1.Cut} Cut
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Cut.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Cut message.
             * @function verify
             * @memberof hudvue.v1.Cut
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Cut.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                return null;
            };

            /**
             * Creates a Cut message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof hudvue.v1.Cut
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {hudvue.v1.Cut} Cut
             */
            Cut.fromObject = function fromObject(object) {
                if (object instanceof $root.hudvue.v1.Cut)
                    return object;
                let message = new $root.hudvue.v1.Cut();
                if (object.id != null)
                    message.id = object.id >>> 0;
                if (object.name != null)
                    message.name = String(object.name);
                return message;
            };

            /**
             * Creates a plain object from a Cut message. Also converts values to other types if specified.
             * @function toObject
             * @memberof hudvue.v1.Cut
             * @static
             * @param {hudvue.v1.Cut} message Cut
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Cut.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.id = 0;
                    object.name = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                return object;
            };

            /**
             * Converts this Cut to JSON.
             * @function toJSON
             * @memberof hudvue.v1.Cut
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Cut.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Cut
             * @function getTypeUrl
             * @memberof hudvue.v1.Cut
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Cut.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/hudvue.v1.Cut";
            };

            return Cut;
        })();

        v1.Grade = (function() {

            /**
             * Properties of a Grade.
             * @memberof hudvue.v1
             * @interface IGrade
             * @property {number|null} [id] Grade id
             * @property {string|null} [name] Grade name
             */

            /**
             * Constructs a new Grade.
             * @memberof hudvue.v1
             * @classdesc Represents a Grade.
             * @implements IGrade
             * @constructor
             * @param {hudvue.v1.IGrade=} [properties] Properties to set
             */
            function Grade(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Grade id.
             * @member {number} id
             * @memberof hudvue.v1.Grade
             * @instance
             */
            Grade.prototype.id = 0;

            /**
             * Grade name.
             * @member {string} name
             * @memberof hudvue.v1.Grade
             * @instance
             */
            Grade.prototype.name = "";

            /**
             * Creates a new Grade instance using the specified properties.
             * @function create
             * @memberof hudvue.v1.Grade
             * @static
             * @param {hudvue.v1.IGrade=} [properties] Properties to set
             * @returns {hudvue.v1.Grade} Grade instance
             */
            Grade.create = function create(properties) {
                return new Grade(properties);
            };

            /**
             * Encodes the specified Grade message. Does not implicitly {@link hudvue.v1.Grade.verify|verify} messages.
             * @function encode
             * @memberof hudvue.v1.Grade
             * @static
             * @param {hudvue.v1.IGrade} message Grade message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Grade.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                return writer;
            };

            /**
             * Encodes the specified Grade message, length delimited. Does not implicitly {@link hudvue.v1.Grade.verify|verify} messages.
             * @function encodeDelimited
             * @memberof hudvue.v1.Grade
             * @static
             * @param {hudvue.v1.IGrade} message Grade message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Grade.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Grade message from the specified reader or buffer.
             * @function decode
             * @memberof hudvue.v1.Grade
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {hudvue.v1.Grade} Grade
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Grade.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hudvue.v1.Grade();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Grade message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof hudvue.v1.Grade
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {hudvue.v1.Grade} Grade
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Grade.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Grade message.
             * @function verify
             * @memberof hudvue.v1.Grade
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Grade.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                return null;
            };

            /**
             * Creates a Grade message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof hudvue.v1.Grade
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {hudvue.v1.Grade} Grade
             */
            Grade.fromObject = function fromObject(object) {
                if (object instanceof $root.hudvue.v1.Grade)
                    return object;
                let message = new $root.hudvue.v1.Grade();
                if (object.id != null)
                    message.id = object.id >>> 0;
                if (object.name != null)
                    message.name = String(object.name);
                return message;
            };

            /**
             * Creates a plain object from a Grade message. Also converts values to other types if specified.
             * @function toObject
             * @memberof hudvue.v1.Grade
             * @static
             * @param {hudvue.v1.Grade} message Grade
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Grade.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.id = 0;
                    object.name = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                return object;
            };

            /**
             * Converts this Grade to JSON.
             * @function toJSON
             * @memberof hudvue.v1.Grade
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Grade.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Grade
             * @function getTypeUrl
             * @memberof hudvue.v1.Grade
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Grade.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/hudvue.v1.Grade";
            };

            return Grade;
        })();

        v1.Slice = (function() {

            /**
             * Properties of a Slice.
             * @memberof hudvue.v1
             * @interface ISlice
             * @property {number|null} [weight] Slice weight
             * @property {hudvue.v1.ITask|null} [taskId] Slice taskId
             * @property {number|null} [cutId] Slice cutId
             * @property {hudvue.v1.ICut|null} [cut] Slice cut
             * @property {number|null} [gradeId] Slice gradeId
             * @property {hudvue.v1.IGrade|null} [grade] Slice grade
             */

            /**
             * Constructs a new Slice.
             * @memberof hudvue.v1
             * @classdesc Represents a Slice.
             * @implements ISlice
             * @constructor
             * @param {hudvue.v1.ISlice=} [properties] Properties to set
             */
            function Slice(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Slice weight.
             * @member {number} weight
             * @memberof hudvue.v1.Slice
             * @instance
             */
            Slice.prototype.weight = 0;

            /**
             * Slice taskId.
             * @member {hudvue.v1.ITask|null|undefined} taskId
             * @memberof hudvue.v1.Slice
             * @instance
             */
            Slice.prototype.taskId = null;

            /**
             * Slice cutId.
             * @member {number} cutId
             * @memberof hudvue.v1.Slice
             * @instance
             */
            Slice.prototype.cutId = 0;

            /**
             * Slice cut.
             * @member {hudvue.v1.ICut|null|undefined} cut
             * @memberof hudvue.v1.Slice
             * @instance
             */
            Slice.prototype.cut = null;

            /**
             * Slice gradeId.
             * @member {number} gradeId
             * @memberof hudvue.v1.Slice
             * @instance
             */
            Slice.prototype.gradeId = 0;

            /**
             * Slice grade.
             * @member {hudvue.v1.IGrade|null|undefined} grade
             * @memberof hudvue.v1.Slice
             * @instance
             */
            Slice.prototype.grade = null;

            /**
             * Creates a new Slice instance using the specified properties.
             * @function create
             * @memberof hudvue.v1.Slice
             * @static
             * @param {hudvue.v1.ISlice=} [properties] Properties to set
             * @returns {hudvue.v1.Slice} Slice instance
             */
            Slice.create = function create(properties) {
                return new Slice(properties);
            };

            /**
             * Encodes the specified Slice message. Does not implicitly {@link hudvue.v1.Slice.verify|verify} messages.
             * @function encode
             * @memberof hudvue.v1.Slice
             * @static
             * @param {hudvue.v1.ISlice} message Slice message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Slice.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.weight != null && Object.hasOwnProperty.call(message, "weight"))
                    writer.uint32(/* id 1, wireType 5 =*/13).float(message.weight);
                if (message.cutId != null && Object.hasOwnProperty.call(message, "cutId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.cutId);
                if (message.cut != null && Object.hasOwnProperty.call(message, "cut"))
                    $root.hudvue.v1.Cut.encode(message.cut, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.gradeId != null && Object.hasOwnProperty.call(message, "gradeId"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.gradeId);
                if (message.grade != null && Object.hasOwnProperty.call(message, "grade"))
                    $root.hudvue.v1.Grade.encode(message.grade, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.taskId != null && Object.hasOwnProperty.call(message, "taskId"))
                    $root.hudvue.v1.Task.encode(message.taskId, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified Slice message, length delimited. Does not implicitly {@link hudvue.v1.Slice.verify|verify} messages.
             * @function encodeDelimited
             * @memberof hudvue.v1.Slice
             * @static
             * @param {hudvue.v1.ISlice} message Slice message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Slice.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Slice message from the specified reader or buffer.
             * @function decode
             * @memberof hudvue.v1.Slice
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {hudvue.v1.Slice} Slice
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Slice.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hudvue.v1.Slice();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.weight = reader.float();
                            break;
                        }
                    case 10: {
                            message.taskId = $root.hudvue.v1.Task.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.cutId = reader.uint32();
                            break;
                        }
                    case 3: {
                            message.cut = $root.hudvue.v1.Cut.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.gradeId = reader.uint32();
                            break;
                        }
                    case 5: {
                            message.grade = $root.hudvue.v1.Grade.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Slice message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof hudvue.v1.Slice
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {hudvue.v1.Slice} Slice
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Slice.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Slice message.
             * @function verify
             * @memberof hudvue.v1.Slice
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Slice.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.weight != null && message.hasOwnProperty("weight"))
                    if (typeof message.weight !== "number")
                        return "weight: number expected";
                if (message.taskId != null && message.hasOwnProperty("taskId")) {
                    let error = $root.hudvue.v1.Task.verify(message.taskId);
                    if (error)
                        return "taskId." + error;
                }
                if (message.cutId != null && message.hasOwnProperty("cutId"))
                    if (!$util.isInteger(message.cutId))
                        return "cutId: integer expected";
                if (message.cut != null && message.hasOwnProperty("cut")) {
                    let error = $root.hudvue.v1.Cut.verify(message.cut);
                    if (error)
                        return "cut." + error;
                }
                if (message.gradeId != null && message.hasOwnProperty("gradeId"))
                    if (!$util.isInteger(message.gradeId))
                        return "gradeId: integer expected";
                if (message.grade != null && message.hasOwnProperty("grade")) {
                    let error = $root.hudvue.v1.Grade.verify(message.grade);
                    if (error)
                        return "grade." + error;
                }
                return null;
            };

            /**
             * Creates a Slice message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof hudvue.v1.Slice
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {hudvue.v1.Slice} Slice
             */
            Slice.fromObject = function fromObject(object) {
                if (object instanceof $root.hudvue.v1.Slice)
                    return object;
                let message = new $root.hudvue.v1.Slice();
                if (object.weight != null)
                    message.weight = Number(object.weight);
                if (object.taskId != null) {
                    if (typeof object.taskId !== "object")
                        throw TypeError(".hudvue.v1.Slice.taskId: object expected");
                    message.taskId = $root.hudvue.v1.Task.fromObject(object.taskId);
                }
                if (object.cutId != null)
                    message.cutId = object.cutId >>> 0;
                if (object.cut != null) {
                    if (typeof object.cut !== "object")
                        throw TypeError(".hudvue.v1.Slice.cut: object expected");
                    message.cut = $root.hudvue.v1.Cut.fromObject(object.cut);
                }
                if (object.gradeId != null)
                    message.gradeId = object.gradeId >>> 0;
                if (object.grade != null) {
                    if (typeof object.grade !== "object")
                        throw TypeError(".hudvue.v1.Slice.grade: object expected");
                    message.grade = $root.hudvue.v1.Grade.fromObject(object.grade);
                }
                return message;
            };

            /**
             * Creates a plain object from a Slice message. Also converts values to other types if specified.
             * @function toObject
             * @memberof hudvue.v1.Slice
             * @static
             * @param {hudvue.v1.Slice} message Slice
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Slice.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.weight = 0;
                    object.cutId = 0;
                    object.cut = null;
                    object.gradeId = 0;
                    object.grade = null;
                    object.taskId = null;
                }
                if (message.weight != null && message.hasOwnProperty("weight"))
                    object.weight = options.json && !isFinite(message.weight) ? String(message.weight) : message.weight;
                if (message.cutId != null && message.hasOwnProperty("cutId"))
                    object.cutId = message.cutId;
                if (message.cut != null && message.hasOwnProperty("cut"))
                    object.cut = $root.hudvue.v1.Cut.toObject(message.cut, options);
                if (message.gradeId != null && message.hasOwnProperty("gradeId"))
                    object.gradeId = message.gradeId;
                if (message.grade != null && message.hasOwnProperty("grade"))
                    object.grade = $root.hudvue.v1.Grade.toObject(message.grade, options);
                if (message.taskId != null && message.hasOwnProperty("taskId"))
                    object.taskId = $root.hudvue.v1.Task.toObject(message.taskId, options);
                return object;
            };

            /**
             * Converts this Slice to JSON.
             * @function toJSON
             * @memberof hudvue.v1.Slice
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Slice.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Slice
             * @function getTypeUrl
             * @memberof hudvue.v1.Slice
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Slice.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/hudvue.v1.Slice";
            };

            return Slice;
        })();

        v1.Task = (function() {

            /**
             * Properties of a Task.
             * @memberof hudvue.v1
             * @interface ITask
             * @property {number|null} [id] Task id
             * @property {string|null} [state] Task state
             * @property {number|null} [startWeight] Task startWeight
             * @property {number|null} [targetWeight] Task targetWeight
             * @property {number|null} [upperWeight] Task upperWeight
             * @property {number|null} [lowerWeight] Task lowerWeight
             * @property {number|null} [endWeight] Task endWeight
             * @property {hudvue.v1.IPerson|null} [employee] Task employee
             * @property {hudvue.v1.ITray|null} [tray] Task tray
             */

            /**
             * Constructs a new Task.
             * @memberof hudvue.v1
             * @classdesc Represents a Task.
             * @implements ITask
             * @constructor
             * @param {hudvue.v1.ITask=} [properties] Properties to set
             */
            function Task(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Task id.
             * @member {number} id
             * @memberof hudvue.v1.Task
             * @instance
             */
            Task.prototype.id = 0;

            /**
             * Task state.
             * @member {string} state
             * @memberof hudvue.v1.Task
             * @instance
             */
            Task.prototype.state = "";

            /**
             * Task startWeight.
             * @member {number} startWeight
             * @memberof hudvue.v1.Task
             * @instance
             */
            Task.prototype.startWeight = 0;

            /**
             * Task targetWeight.
             * @member {number} targetWeight
             * @memberof hudvue.v1.Task
             * @instance
             */
            Task.prototype.targetWeight = 0;

            /**
             * Task upperWeight.
             * @member {number} upperWeight
             * @memberof hudvue.v1.Task
             * @instance
             */
            Task.prototype.upperWeight = 0;

            /**
             * Task lowerWeight.
             * @member {number} lowerWeight
             * @memberof hudvue.v1.Task
             * @instance
             */
            Task.prototype.lowerWeight = 0;

            /**
             * Task endWeight.
             * @member {number} endWeight
             * @memberof hudvue.v1.Task
             * @instance
             */
            Task.prototype.endWeight = 0;

            /**
             * Task employee.
             * @member {hudvue.v1.IPerson|null|undefined} employee
             * @memberof hudvue.v1.Task
             * @instance
             */
            Task.prototype.employee = null;

            /**
             * Task tray.
             * @member {hudvue.v1.ITray|null|undefined} tray
             * @memberof hudvue.v1.Task
             * @instance
             */
            Task.prototype.tray = null;

            /**
             * Creates a new Task instance using the specified properties.
             * @function create
             * @memberof hudvue.v1.Task
             * @static
             * @param {hudvue.v1.ITask=} [properties] Properties to set
             * @returns {hudvue.v1.Task} Task instance
             */
            Task.create = function create(properties) {
                return new Task(properties);
            };

            /**
             * Encodes the specified Task message. Does not implicitly {@link hudvue.v1.Task.verify|verify} messages.
             * @function encode
             * @memberof hudvue.v1.Task
             * @static
             * @param {hudvue.v1.ITask} message Task message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Task.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.state);
                if (message.startWeight != null && Object.hasOwnProperty.call(message, "startWeight"))
                    writer.uint32(/* id 3, wireType 5 =*/29).float(message.startWeight);
                if (message.targetWeight != null && Object.hasOwnProperty.call(message, "targetWeight"))
                    writer.uint32(/* id 4, wireType 5 =*/37).float(message.targetWeight);
                if (message.upperWeight != null && Object.hasOwnProperty.call(message, "upperWeight"))
                    writer.uint32(/* id 5, wireType 5 =*/45).float(message.upperWeight);
                if (message.lowerWeight != null && Object.hasOwnProperty.call(message, "lowerWeight"))
                    writer.uint32(/* id 6, wireType 5 =*/53).float(message.lowerWeight);
                if (message.endWeight != null && Object.hasOwnProperty.call(message, "endWeight"))
                    writer.uint32(/* id 7, wireType 5 =*/61).float(message.endWeight);
                if (message.employee != null && Object.hasOwnProperty.call(message, "employee"))
                    $root.hudvue.v1.Person.encode(message.employee, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.tray != null && Object.hasOwnProperty.call(message, "tray"))
                    $root.hudvue.v1.Tray.encode(message.tray, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified Task message, length delimited. Does not implicitly {@link hudvue.v1.Task.verify|verify} messages.
             * @function encodeDelimited
             * @memberof hudvue.v1.Task
             * @static
             * @param {hudvue.v1.ITask} message Task message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Task.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Task message from the specified reader or buffer.
             * @function decode
             * @memberof hudvue.v1.Task
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {hudvue.v1.Task} Task
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Task.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hudvue.v1.Task();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.state = reader.string();
                            break;
                        }
                    case 3: {
                            message.startWeight = reader.float();
                            break;
                        }
                    case 4: {
                            message.targetWeight = reader.float();
                            break;
                        }
                    case 5: {
                            message.upperWeight = reader.float();
                            break;
                        }
                    case 6: {
                            message.lowerWeight = reader.float();
                            break;
                        }
                    case 7: {
                            message.endWeight = reader.float();
                            break;
                        }
                    case 10: {
                            message.employee = $root.hudvue.v1.Person.decode(reader, reader.uint32());
                            break;
                        }
                    case 11: {
                            message.tray = $root.hudvue.v1.Tray.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Task message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof hudvue.v1.Task
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {hudvue.v1.Task} Task
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Task.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Task message.
             * @function verify
             * @memberof hudvue.v1.Task
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Task.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.state != null && message.hasOwnProperty("state"))
                    if (!$util.isString(message.state))
                        return "state: string expected";
                if (message.startWeight != null && message.hasOwnProperty("startWeight"))
                    if (typeof message.startWeight !== "number")
                        return "startWeight: number expected";
                if (message.targetWeight != null && message.hasOwnProperty("targetWeight"))
                    if (typeof message.targetWeight !== "number")
                        return "targetWeight: number expected";
                if (message.upperWeight != null && message.hasOwnProperty("upperWeight"))
                    if (typeof message.upperWeight !== "number")
                        return "upperWeight: number expected";
                if (message.lowerWeight != null && message.hasOwnProperty("lowerWeight"))
                    if (typeof message.lowerWeight !== "number")
                        return "lowerWeight: number expected";
                if (message.endWeight != null && message.hasOwnProperty("endWeight"))
                    if (typeof message.endWeight !== "number")
                        return "endWeight: number expected";
                if (message.employee != null && message.hasOwnProperty("employee")) {
                    let error = $root.hudvue.v1.Person.verify(message.employee);
                    if (error)
                        return "employee." + error;
                }
                if (message.tray != null && message.hasOwnProperty("tray")) {
                    let error = $root.hudvue.v1.Tray.verify(message.tray);
                    if (error)
                        return "tray." + error;
                }
                return null;
            };

            /**
             * Creates a Task message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof hudvue.v1.Task
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {hudvue.v1.Task} Task
             */
            Task.fromObject = function fromObject(object) {
                if (object instanceof $root.hudvue.v1.Task)
                    return object;
                let message = new $root.hudvue.v1.Task();
                if (object.id != null)
                    message.id = object.id >>> 0;
                if (object.state != null)
                    message.state = String(object.state);
                if (object.startWeight != null)
                    message.startWeight = Number(object.startWeight);
                if (object.targetWeight != null)
                    message.targetWeight = Number(object.targetWeight);
                if (object.upperWeight != null)
                    message.upperWeight = Number(object.upperWeight);
                if (object.lowerWeight != null)
                    message.lowerWeight = Number(object.lowerWeight);
                if (object.endWeight != null)
                    message.endWeight = Number(object.endWeight);
                if (object.employee != null) {
                    if (typeof object.employee !== "object")
                        throw TypeError(".hudvue.v1.Task.employee: object expected");
                    message.employee = $root.hudvue.v1.Person.fromObject(object.employee);
                }
                if (object.tray != null) {
                    if (typeof object.tray !== "object")
                        throw TypeError(".hudvue.v1.Task.tray: object expected");
                    message.tray = $root.hudvue.v1.Tray.fromObject(object.tray);
                }
                return message;
            };

            /**
             * Creates a plain object from a Task message. Also converts values to other types if specified.
             * @function toObject
             * @memberof hudvue.v1.Task
             * @static
             * @param {hudvue.v1.Task} message Task
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Task.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.id = 0;
                    object.state = "";
                    object.startWeight = 0;
                    object.targetWeight = 0;
                    object.upperWeight = 0;
                    object.lowerWeight = 0;
                    object.endWeight = 0;
                    object.employee = null;
                    object.tray = null;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.state != null && message.hasOwnProperty("state"))
                    object.state = message.state;
                if (message.startWeight != null && message.hasOwnProperty("startWeight"))
                    object.startWeight = options.json && !isFinite(message.startWeight) ? String(message.startWeight) : message.startWeight;
                if (message.targetWeight != null && message.hasOwnProperty("targetWeight"))
                    object.targetWeight = options.json && !isFinite(message.targetWeight) ? String(message.targetWeight) : message.targetWeight;
                if (message.upperWeight != null && message.hasOwnProperty("upperWeight"))
                    object.upperWeight = options.json && !isFinite(message.upperWeight) ? String(message.upperWeight) : message.upperWeight;
                if (message.lowerWeight != null && message.hasOwnProperty("lowerWeight"))
                    object.lowerWeight = options.json && !isFinite(message.lowerWeight) ? String(message.lowerWeight) : message.lowerWeight;
                if (message.endWeight != null && message.hasOwnProperty("endWeight"))
                    object.endWeight = options.json && !isFinite(message.endWeight) ? String(message.endWeight) : message.endWeight;
                if (message.employee != null && message.hasOwnProperty("employee"))
                    object.employee = $root.hudvue.v1.Person.toObject(message.employee, options);
                if (message.tray != null && message.hasOwnProperty("tray"))
                    object.tray = $root.hudvue.v1.Tray.toObject(message.tray, options);
                return object;
            };

            /**
             * Converts this Task to JSON.
             * @function toJSON
             * @memberof hudvue.v1.Task
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Task.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Task
             * @function getTypeUrl
             * @memberof hudvue.v1.Task
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Task.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/hudvue.v1.Task";
            };

            return Task;
        })();

        v1.Hud = (function() {

            /**
             * Properties of a Hud.
             * @memberof hudvue.v1
             * @interface IHud
             * @property {string|null} [name] Hud name
             * @property {string|null} [code] Hud code
             * @property {hudvue.v1.IPerson|null} [employee] Hud employee
             * @property {hudvue.v1.ICard|null} [card] Hud card
             * @property {hudvue.v1.ITask|null} [task] Hud task
             * @property {string|null} [guidance] Hud guidance
             * @property {string|null} [errorMessage] Hud errorMessage
             */

            /**
             * Constructs a new Hud.
             * @memberof hudvue.v1
             * @classdesc Represents a Hud.
             * @implements IHud
             * @constructor
             * @param {hudvue.v1.IHud=} [properties] Properties to set
             */
            function Hud(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Hud name.
             * @member {string} name
             * @memberof hudvue.v1.Hud
             * @instance
             */
            Hud.prototype.name = "";

            /**
             * Hud code.
             * @member {string} code
             * @memberof hudvue.v1.Hud
             * @instance
             */
            Hud.prototype.code = "";

            /**
             * Hud employee.
             * @member {hudvue.v1.IPerson|null|undefined} employee
             * @memberof hudvue.v1.Hud
             * @instance
             */
            Hud.prototype.employee = null;

            /**
             * Hud card.
             * @member {hudvue.v1.ICard|null|undefined} card
             * @memberof hudvue.v1.Hud
             * @instance
             */
            Hud.prototype.card = null;

            /**
             * Hud task.
             * @member {hudvue.v1.ITask|null|undefined} task
             * @memberof hudvue.v1.Hud
             * @instance
             */
            Hud.prototype.task = null;

            /**
             * Hud guidance.
             * @member {string} guidance
             * @memberof hudvue.v1.Hud
             * @instance
             */
            Hud.prototype.guidance = "";

            /**
             * Hud errorMessage.
             * @member {string} errorMessage
             * @memberof hudvue.v1.Hud
             * @instance
             */
            Hud.prototype.errorMessage = "";

            /**
             * Creates a new Hud instance using the specified properties.
             * @function create
             * @memberof hudvue.v1.Hud
             * @static
             * @param {hudvue.v1.IHud=} [properties] Properties to set
             * @returns {hudvue.v1.Hud} Hud instance
             */
            Hud.create = function create(properties) {
                return new Hud(properties);
            };

            /**
             * Encodes the specified Hud message. Does not implicitly {@link hudvue.v1.Hud.verify|verify} messages.
             * @function encode
             * @memberof hudvue.v1.Hud
             * @static
             * @param {hudvue.v1.IHud} message Hud message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Hud.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.code);
                if (message.employee != null && Object.hasOwnProperty.call(message, "employee"))
                    $root.hudvue.v1.Person.encode(message.employee, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.card != null && Object.hasOwnProperty.call(message, "card"))
                    $root.hudvue.v1.Card.encode(message.card, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.task != null && Object.hasOwnProperty.call(message, "task"))
                    $root.hudvue.v1.Task.encode(message.task, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.guidance != null && Object.hasOwnProperty.call(message, "guidance"))
                    writer.uint32(/* id 98, wireType 2 =*/786).string(message.guidance);
                if (message.errorMessage != null && Object.hasOwnProperty.call(message, "errorMessage"))
                    writer.uint32(/* id 99, wireType 2 =*/794).string(message.errorMessage);
                return writer;
            };

            /**
             * Encodes the specified Hud message, length delimited. Does not implicitly {@link hudvue.v1.Hud.verify|verify} messages.
             * @function encodeDelimited
             * @memberof hudvue.v1.Hud
             * @static
             * @param {hudvue.v1.IHud} message Hud message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Hud.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Hud message from the specified reader or buffer.
             * @function decode
             * @memberof hudvue.v1.Hud
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {hudvue.v1.Hud} Hud
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Hud.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hudvue.v1.Hud();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.code = reader.string();
                            break;
                        }
                    case 3: {
                            message.employee = $root.hudvue.v1.Person.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.card = $root.hudvue.v1.Card.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.task = $root.hudvue.v1.Task.decode(reader, reader.uint32());
                            break;
                        }
                    case 98: {
                            message.guidance = reader.string();
                            break;
                        }
                    case 99: {
                            message.errorMessage = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Hud message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof hudvue.v1.Hud
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {hudvue.v1.Hud} Hud
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Hud.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Hud message.
             * @function verify
             * @memberof hudvue.v1.Hud
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Hud.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    if (!$util.isString(message.code))
                        return "code: string expected";
                if (message.employee != null && message.hasOwnProperty("employee")) {
                    let error = $root.hudvue.v1.Person.verify(message.employee);
                    if (error)
                        return "employee." + error;
                }
                if (message.card != null && message.hasOwnProperty("card")) {
                    let error = $root.hudvue.v1.Card.verify(message.card);
                    if (error)
                        return "card." + error;
                }
                if (message.task != null && message.hasOwnProperty("task")) {
                    let error = $root.hudvue.v1.Task.verify(message.task);
                    if (error)
                        return "task." + error;
                }
                if (message.guidance != null && message.hasOwnProperty("guidance"))
                    if (!$util.isString(message.guidance))
                        return "guidance: string expected";
                if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                    if (!$util.isString(message.errorMessage))
                        return "errorMessage: string expected";
                return null;
            };

            /**
             * Creates a Hud message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof hudvue.v1.Hud
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {hudvue.v1.Hud} Hud
             */
            Hud.fromObject = function fromObject(object) {
                if (object instanceof $root.hudvue.v1.Hud)
                    return object;
                let message = new $root.hudvue.v1.Hud();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.code != null)
                    message.code = String(object.code);
                if (object.employee != null) {
                    if (typeof object.employee !== "object")
                        throw TypeError(".hudvue.v1.Hud.employee: object expected");
                    message.employee = $root.hudvue.v1.Person.fromObject(object.employee);
                }
                if (object.card != null) {
                    if (typeof object.card !== "object")
                        throw TypeError(".hudvue.v1.Hud.card: object expected");
                    message.card = $root.hudvue.v1.Card.fromObject(object.card);
                }
                if (object.task != null) {
                    if (typeof object.task !== "object")
                        throw TypeError(".hudvue.v1.Hud.task: object expected");
                    message.task = $root.hudvue.v1.Task.fromObject(object.task);
                }
                if (object.guidance != null)
                    message.guidance = String(object.guidance);
                if (object.errorMessage != null)
                    message.errorMessage = String(object.errorMessage);
                return message;
            };

            /**
             * Creates a plain object from a Hud message. Also converts values to other types if specified.
             * @function toObject
             * @memberof hudvue.v1.Hud
             * @static
             * @param {hudvue.v1.Hud} message Hud
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Hud.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.code = "";
                    object.employee = null;
                    object.card = null;
                    object.task = null;
                    object.guidance = "";
                    object.errorMessage = "";
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = message.code;
                if (message.employee != null && message.hasOwnProperty("employee"))
                    object.employee = $root.hudvue.v1.Person.toObject(message.employee, options);
                if (message.card != null && message.hasOwnProperty("card"))
                    object.card = $root.hudvue.v1.Card.toObject(message.card, options);
                if (message.task != null && message.hasOwnProperty("task"))
                    object.task = $root.hudvue.v1.Task.toObject(message.task, options);
                if (message.guidance != null && message.hasOwnProperty("guidance"))
                    object.guidance = message.guidance;
                if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                    object.errorMessage = message.errorMessage;
                return object;
            };

            /**
             * Converts this Hud to JSON.
             * @function toJSON
             * @memberof hudvue.v1.Hud
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Hud.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Hud
             * @function getTypeUrl
             * @memberof hudvue.v1.Hud
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Hud.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/hudvue.v1.Hud";
            };

            return Hud;
        })();

        v1.ProducerReport = (function() {

            /**
             * Properties of a ProducerReport.
             * @memberof hudvue.v1
             * @interface IProducerReport
             * @property {number|null} [employeeId] ProducerReport employeeId
             * @property {string|null} [employeeName] ProducerReport employeeName
             * @property {number|null} [startWeight] ProducerReport startWeight
             * @property {number|null} [endWeight] ProducerReport endWeight
             */

            /**
             * Constructs a new ProducerReport.
             * @memberof hudvue.v1
             * @classdesc Represents a ProducerReport.
             * @implements IProducerReport
             * @constructor
             * @param {hudvue.v1.IProducerReport=} [properties] Properties to set
             */
            function ProducerReport(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ProducerReport employeeId.
             * @member {number} employeeId
             * @memberof hudvue.v1.ProducerReport
             * @instance
             */
            ProducerReport.prototype.employeeId = 0;

            /**
             * ProducerReport employeeName.
             * @member {string} employeeName
             * @memberof hudvue.v1.ProducerReport
             * @instance
             */
            ProducerReport.prototype.employeeName = "";

            /**
             * ProducerReport startWeight.
             * @member {number} startWeight
             * @memberof hudvue.v1.ProducerReport
             * @instance
             */
            ProducerReport.prototype.startWeight = 0;

            /**
             * ProducerReport endWeight.
             * @member {number} endWeight
             * @memberof hudvue.v1.ProducerReport
             * @instance
             */
            ProducerReport.prototype.endWeight = 0;

            /**
             * Creates a new ProducerReport instance using the specified properties.
             * @function create
             * @memberof hudvue.v1.ProducerReport
             * @static
             * @param {hudvue.v1.IProducerReport=} [properties] Properties to set
             * @returns {hudvue.v1.ProducerReport} ProducerReport instance
             */
            ProducerReport.create = function create(properties) {
                return new ProducerReport(properties);
            };

            /**
             * Encodes the specified ProducerReport message. Does not implicitly {@link hudvue.v1.ProducerReport.verify|verify} messages.
             * @function encode
             * @memberof hudvue.v1.ProducerReport
             * @static
             * @param {hudvue.v1.IProducerReport} message ProducerReport message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ProducerReport.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.employeeId != null && Object.hasOwnProperty.call(message, "employeeId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.employeeId);
                if (message.employeeName != null && Object.hasOwnProperty.call(message, "employeeName"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.employeeName);
                if (message.startWeight != null && Object.hasOwnProperty.call(message, "startWeight"))
                    writer.uint32(/* id 3, wireType 5 =*/29).float(message.startWeight);
                if (message.endWeight != null && Object.hasOwnProperty.call(message, "endWeight"))
                    writer.uint32(/* id 4, wireType 5 =*/37).float(message.endWeight);
                return writer;
            };

            /**
             * Encodes the specified ProducerReport message, length delimited. Does not implicitly {@link hudvue.v1.ProducerReport.verify|verify} messages.
             * @function encodeDelimited
             * @memberof hudvue.v1.ProducerReport
             * @static
             * @param {hudvue.v1.IProducerReport} message ProducerReport message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ProducerReport.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ProducerReport message from the specified reader or buffer.
             * @function decode
             * @memberof hudvue.v1.ProducerReport
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {hudvue.v1.ProducerReport} ProducerReport
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ProducerReport.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hudvue.v1.ProducerReport();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.employeeId = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.employeeName = reader.string();
                            break;
                        }
                    case 3: {
                            message.startWeight = reader.float();
                            break;
                        }
                    case 4: {
                            message.endWeight = reader.float();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ProducerReport message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof hudvue.v1.ProducerReport
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {hudvue.v1.ProducerReport} ProducerReport
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ProducerReport.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ProducerReport message.
             * @function verify
             * @memberof hudvue.v1.ProducerReport
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ProducerReport.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.employeeId != null && message.hasOwnProperty("employeeId"))
                    if (!$util.isInteger(message.employeeId))
                        return "employeeId: integer expected";
                if (message.employeeName != null && message.hasOwnProperty("employeeName"))
                    if (!$util.isString(message.employeeName))
                        return "employeeName: string expected";
                if (message.startWeight != null && message.hasOwnProperty("startWeight"))
                    if (typeof message.startWeight !== "number")
                        return "startWeight: number expected";
                if (message.endWeight != null && message.hasOwnProperty("endWeight"))
                    if (typeof message.endWeight !== "number")
                        return "endWeight: number expected";
                return null;
            };

            /**
             * Creates a ProducerReport message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof hudvue.v1.ProducerReport
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {hudvue.v1.ProducerReport} ProducerReport
             */
            ProducerReport.fromObject = function fromObject(object) {
                if (object instanceof $root.hudvue.v1.ProducerReport)
                    return object;
                let message = new $root.hudvue.v1.ProducerReport();
                if (object.employeeId != null)
                    message.employeeId = object.employeeId >>> 0;
                if (object.employeeName != null)
                    message.employeeName = String(object.employeeName);
                if (object.startWeight != null)
                    message.startWeight = Number(object.startWeight);
                if (object.endWeight != null)
                    message.endWeight = Number(object.endWeight);
                return message;
            };

            /**
             * Creates a plain object from a ProducerReport message. Also converts values to other types if specified.
             * @function toObject
             * @memberof hudvue.v1.ProducerReport
             * @static
             * @param {hudvue.v1.ProducerReport} message ProducerReport
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ProducerReport.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.employeeId = 0;
                    object.employeeName = "";
                    object.startWeight = 0;
                    object.endWeight = 0;
                }
                if (message.employeeId != null && message.hasOwnProperty("employeeId"))
                    object.employeeId = message.employeeId;
                if (message.employeeName != null && message.hasOwnProperty("employeeName"))
                    object.employeeName = message.employeeName;
                if (message.startWeight != null && message.hasOwnProperty("startWeight"))
                    object.startWeight = options.json && !isFinite(message.startWeight) ? String(message.startWeight) : message.startWeight;
                if (message.endWeight != null && message.hasOwnProperty("endWeight"))
                    object.endWeight = options.json && !isFinite(message.endWeight) ? String(message.endWeight) : message.endWeight;
                return object;
            };

            /**
             * Converts this ProducerReport to JSON.
             * @function toJSON
             * @memberof hudvue.v1.ProducerReport
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ProducerReport.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ProducerReport
             * @function getTypeUrl
             * @memberof hudvue.v1.ProducerReport
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ProducerReport.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/hudvue.v1.ProducerReport";
            };

            return ProducerReport;
        })();

        v1.ProducerReports = (function() {

            /**
             * Properties of a ProducerReports.
             * @memberof hudvue.v1
             * @interface IProducerReports
             * @property {Array.<hudvue.v1.IProducerReport>|null} [ProducerReports] ProducerReports ProducerReports
             */

            /**
             * Constructs a new ProducerReports.
             * @memberof hudvue.v1
             * @classdesc Represents a ProducerReports.
             * @implements IProducerReports
             * @constructor
             * @param {hudvue.v1.IProducerReports=} [properties] Properties to set
             */
            function ProducerReports(properties) {
                this.ProducerReports = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ProducerReports ProducerReports.
             * @member {Array.<hudvue.v1.IProducerReport>} ProducerReports
             * @memberof hudvue.v1.ProducerReports
             * @instance
             */
            ProducerReports.prototype.ProducerReports = $util.emptyArray;

            /**
             * Creates a new ProducerReports instance using the specified properties.
             * @function create
             * @memberof hudvue.v1.ProducerReports
             * @static
             * @param {hudvue.v1.IProducerReports=} [properties] Properties to set
             * @returns {hudvue.v1.ProducerReports} ProducerReports instance
             */
            ProducerReports.create = function create(properties) {
                return new ProducerReports(properties);
            };

            /**
             * Encodes the specified ProducerReports message. Does not implicitly {@link hudvue.v1.ProducerReports.verify|verify} messages.
             * @function encode
             * @memberof hudvue.v1.ProducerReports
             * @static
             * @param {hudvue.v1.IProducerReports} message ProducerReports message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ProducerReports.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.ProducerReports != null && message.ProducerReports.length)
                    for (let i = 0; i < message.ProducerReports.length; ++i)
                        $root.hudvue.v1.ProducerReport.encode(message.ProducerReports[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ProducerReports message, length delimited. Does not implicitly {@link hudvue.v1.ProducerReports.verify|verify} messages.
             * @function encodeDelimited
             * @memberof hudvue.v1.ProducerReports
             * @static
             * @param {hudvue.v1.IProducerReports} message ProducerReports message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ProducerReports.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ProducerReports message from the specified reader or buffer.
             * @function decode
             * @memberof hudvue.v1.ProducerReports
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {hudvue.v1.ProducerReports} ProducerReports
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ProducerReports.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hudvue.v1.ProducerReports();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.ProducerReports && message.ProducerReports.length))
                                message.ProducerReports = [];
                            message.ProducerReports.push($root.hudvue.v1.ProducerReport.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ProducerReports message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof hudvue.v1.ProducerReports
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {hudvue.v1.ProducerReports} ProducerReports
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ProducerReports.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ProducerReports message.
             * @function verify
             * @memberof hudvue.v1.ProducerReports
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ProducerReports.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.ProducerReports != null && message.hasOwnProperty("ProducerReports")) {
                    if (!Array.isArray(message.ProducerReports))
                        return "ProducerReports: array expected";
                    for (let i = 0; i < message.ProducerReports.length; ++i) {
                        let error = $root.hudvue.v1.ProducerReport.verify(message.ProducerReports[i]);
                        if (error)
                            return "ProducerReports." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ProducerReports message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof hudvue.v1.ProducerReports
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {hudvue.v1.ProducerReports} ProducerReports
             */
            ProducerReports.fromObject = function fromObject(object) {
                if (object instanceof $root.hudvue.v1.ProducerReports)
                    return object;
                let message = new $root.hudvue.v1.ProducerReports();
                if (object.ProducerReports) {
                    if (!Array.isArray(object.ProducerReports))
                        throw TypeError(".hudvue.v1.ProducerReports.ProducerReports: array expected");
                    message.ProducerReports = [];
                    for (let i = 0; i < object.ProducerReports.length; ++i) {
                        if (typeof object.ProducerReports[i] !== "object")
                            throw TypeError(".hudvue.v1.ProducerReports.ProducerReports: object expected");
                        message.ProducerReports[i] = $root.hudvue.v1.ProducerReport.fromObject(object.ProducerReports[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a ProducerReports message. Also converts values to other types if specified.
             * @function toObject
             * @memberof hudvue.v1.ProducerReports
             * @static
             * @param {hudvue.v1.ProducerReports} message ProducerReports
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ProducerReports.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.ProducerReports = [];
                if (message.ProducerReports && message.ProducerReports.length) {
                    object.ProducerReports = [];
                    for (let j = 0; j < message.ProducerReports.length; ++j)
                        object.ProducerReports[j] = $root.hudvue.v1.ProducerReport.toObject(message.ProducerReports[j], options);
                }
                return object;
            };

            /**
             * Converts this ProducerReports to JSON.
             * @function toJSON
             * @memberof hudvue.v1.ProducerReports
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ProducerReports.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ProducerReports
             * @function getTypeUrl
             * @memberof hudvue.v1.ProducerReports
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ProducerReports.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/hudvue.v1.ProducerReports";
            };

            return ProducerReports;
        })();

        return v1;
    })();

    return hudvue;
})();

export { $root as default };
