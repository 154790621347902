<script setup>
import { ref, reactive, watch, computed, onBeforeMount, onMounted } from "vue";
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
} from "@headlessui/vue";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/vue/outline";
import { PlusSmIcon } from "@heroicons/vue/solid";
import { useRouter, useRoute } from "vue-router";

const menu_items = ref([
    { name: "Pre-Check", path: "/realtime/precheck", current: true },
    { name: "Post-Check", path: "/realtime/postcheck", current: true },
    { name: "Analytics", path: "/analytics", current: false },
    { name: "About", path: "/about", current: false },
]);

const router = useRouter();
const route = useRoute();
const currentPath = computed(() => route.path);

//window.cr = router.currentRoute
</script>

<template>
    <Disclosure as="nav" class="bg-white shadow" v-slot="{ open }">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex justify-between h-16">
                <div class="flex">
                    <div class="-ml-2 mr-2 flex items-center md:hidden">
                        <!-- Mobile menu button -->
                        <DisclosureButton
                            class="
                                inline-flex
                                items-center
                                justify-center
                                p-2
                                rounded-md
                                text-gray-400
                                hover:text-gray-500 hover:bg-gray-100
                                focus:outline-none
                                focus:ring-2
                                focus:ring-inset
                                focus:ring-indigo-500
                            "
                        >
                            <span class="sr-only">Open main menu</span>
                            <MenuIcon
                                v-if="!open"
                                class="block h-6 w-6"
                                aria-hidden="true"
                            />
                            <XIcon
                                v-else
                                class="block h-6 w-6"
                                aria-hidden="true"
                            />
                        </DisclosureButton>
                    </div>
                    <div class="flex-shrink-0 flex items-center">
                        <div
                            class="
                                border-transparent
                                text-gray-900
                                inline-flex
                                items-center
                                px-1
                                pt-1
                                border-b-2
                                text-md
                                font-bold
                                "
                        >
                        YieldMAX
                        </div>
                        <!--
                        <img
                            class="block lg:hidden h-8 w-auto"
                            src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                            alt="Workflow"
                        />
                        <img
                            class="hidden lg:block h-8 w-auto"
                            src="/img/robotsqwhite.jpg"
                            alt="Workflow"
                        />
                        -->
                    </div>
                    <div class="hidden md:ml-6 md:flex md:space-x-8">
                        <router-link v-for="item in menu_items" 
                            :key="item.name" 
                            :to="item.path"
                            :class="[ item.path === currentPath ? 'text-black-900 border-gray-700 font-bold text-md' : 'text-sm text-gray-500', 'border-transparent hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 font-medium'] "
                        >{{item.name}}</router-link>
                    </div>
                </div>
                <div class="flex items-center">
                    <!--
                    <div class="flex-shrink-0">
                        <button
                            type="button"
                            class="
                                relative
                                inline-flex
                                items-center
                                px-4
                                py-2
                                border border-transparent
                                text-sm
                                font-medium
                                rounded-md
                                text-white
                                bg-indigo-600
                                shadow-sm
                                hover:bg-indigo-700
                                focus:outline-none
                                focus:ring-2
                                focus:ring-offset-2
                                focus:ring-indigo-500
                            "
                        >
                            <PlusSmIcon
                                class="-ml-1 mr-2 h-5 w-5"
                                aria-hidden="true"
                            />
                            <span>New Job</span>
                        </button>
                    </div>
                    -->
                    <div
                        class="
                            hidden
                            md:ml-4 md:flex-shrink-0 md:flex md:items-center
                        "
                    >
                        <button
                            type="button"
                            class="
                                bg-white
                                p-1
                                rounded-full
                                text-gray-400
                                hover:text-gray-500
                                focus:outline-none
                                focus:ring-2
                                focus:ring-offset-2
                                focus:ring-indigo-500
                            "
                        >
                            <span class="sr-only">View notifications</span>
                            <BellIcon class="h-6 w-6" aria-hidden="true" />
                        </button>

                        <!-- Profile dropdown -->
                        <Menu as="div" class="ml-3 relative">
                            <div>
                                <MenuButton
                                    class="
                                        bg-white
                                        rounded-full
                                        flex
                                        text-sm
                                        focus:outline-none
                                        focus:ring-2
                                        focus:ring-offset-2
                                        focus:ring-indigo-500
                                    "
                                >
                                    <span class="sr-only">Open user menu</span>
                                    <img
                                        class="h-8 w-8 rounded-full"
                                        src="/img/robotsqwhite.jpg"
                                        alt=""
                                    />
                                </MenuButton>
                            </div>
                            <transition
                                enter-active-class="transition ease-out duration-200"
                                enter-from-class="transform opacity-0 scale-95"
                                enter-to-class="transform opacity-100 scale-100"
                                leave-active-class="transition ease-in duration-75"
                                leave-from-class="transform opacity-100 scale-100"
                                leave-to-class="transform opacity-0 scale-95"
                            >
                                <MenuItems
                                    class="
                                        origin-top-right
                                        absolute
                                        right-0
                                        mt-2
                                        w-48
                                        rounded-md
                                        shadow-lg
                                        py-1
                                        bg-white
                                        ring-1 ring-black ring-opacity-5
                                        focus:outline-none
                                    "
                                >
                                    <MenuItem v-slot="{ active }">
                                        <a
                                            href="#"
                                            :class="[
                                                active ? 'bg-gray-100' : '',
                                                'block px-4 py-2 text-sm text-gray-700',
                                            ]"
                                            >Your Profile</a
                                        >
                                    </MenuItem>
                                    <MenuItem v-slot="{ active }">
                                        <a
                                            href="#"
                                            :class="[
                                                active ? 'bg-gray-100' : '',
                                                'block px-4 py-2 text-sm text-gray-700',
                                            ]"
                                            >Settings</a
                                        >
                                    </MenuItem>
                                    <MenuItem v-slot="{ active }">
                                        <a
                                            href="#"
                                            :class="[
                                                active ? 'bg-gray-100' : '',
                                                'block px-4 py-2 text-sm text-gray-700',
                                            ]"
                                            >Sign out</a
                                        >
                                    </MenuItem>
                                </MenuItems>
                            </transition>
                        </Menu>
                    </div>
                </div>
            </div>
        </div>

        <DisclosurePanel class="md:hidden">
            <div class="pt-2 pb-3 space-y-1">
                    <router-link v-for="item in menu_items" 
                        :key="item.name" 
                        :to="item.path"
                        class=" border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
                    >{{item.name}}</router-link>
            </div>
            <div class="pt-4 pb-3 border-t border-gray-200">
                <div class="flex items-center px-4 sm:px-6">
                    <div class="flex-shrink-0">
                        <img
                            class="h-10 w-10 rounded-full"
                            src="/public/img/robotsqwhite.jpg"
                            alt=""
                        />
                    </div>
                    <div class="ml-3">
                        <div class="text-base font-medium text-gray-800">
                            Big Boss
                        </div>
                        <div class="text-sm font-medium text-gray-500">
                            big@boss.com
                        </div>
                    </div>
                    <button
                        type="button"
                        class="
                            ml-auto
                            flex-shrink-0
                            bg-white
                            p-1
                            rounded-full
                            text-gray-400
                            hover:text-gray-500
                            focus:outline-none
                            focus:ring-2
                            focus:ring-offset-2
                            focus:ring-indigo-500
                        "
                    >
                        <span class="sr-only">View notifications</span>
                        <BellIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div class="mt-3 space-y-1">
                    <DisclosureButton
                        as="a"
                        href="#"
                        class="
                            block
                            px-4
                            py-2
                            text-base
                            font-medium
                            text-gray-500
                            hover:text-gray-800 hover:bg-gray-100
                            sm:px-6
                        "
                        >Your Profile</DisclosureButton
                    >
                    <DisclosureButton
                        as="a"
                        href="#"
                        class="
                            block
                            px-4
                            py-2
                            text-base
                            font-medium
                            text-gray-500
                            hover:text-gray-800 hover:bg-gray-100
                            sm:px-6
                        "
                        >Settings</DisclosureButton
                    >
                    <DisclosureButton
                        as="a"
                        href="#"
                        class="
                            block
                            px-4
                            py-2
                            text-base
                            font-medium
                            text-gray-500
                            hover:text-gray-800 hover:bg-gray-100
                            sm:px-6
                        "
                        >Sign out</DisclosureButton
                    >
                </div>
            </div>
        </DisclosurePanel>
    </Disclosure>
</template>
