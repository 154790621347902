<template>
    <h1>Form Title</h1>
    Route Params: {{ $route.params }}

    <div>
        <div class="relative">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
                <div class="w-full border-t border-gray-300" />
            </div>
            <div class="relative flex justify-center">
                <span class="px-3 bg-white text-lg font-medium text-gray-900">
                    Projects
                </span>
            </div>
        </div>
        <br />
        <hr />
        <hr />
        <hr />
        <hr />
        <br />
        <button
            @click="openFlyOver"
            type="button"
            class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
            Open Flyover
        </button>
        <button
            type="button"
            class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
            Button text
        </button>
        FOV: {{ flyOverVisible }}
        <FlyOver :visible="flyOverVisible" v-on:hiding="hideFlyOver" />
    </div>
</template>

<script setup>
import { ref, reactive, watch, computed } from "vue";

import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/solid";
import FlyOver from "../components/FlyOver.vue";

const flyOverVisible = ref(false);

function openFlyOver() {
    console.log("openFlyOver => true");
    flyOverVisible.value = true;
}
function hideFlyOver() {
    console.log("openFlyOver => false");
    flyOverVisible.value = false;
}
</script>
<script></script>

<style></style>
