<script setup>
import { ref, reactive, watch, computed, onBeforeMount, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

const store = useStore();
const producer_reports = computed(() => store.state.producer_reports);

/*
const producers = [
    { id: 1, name: "John Doe", start: "123.0", end: "75.00", price: "90.00" },
    { id: 2, name: "John Doe", start: "123.0", end: "75.00", price: "90.00" },
    { id: 3, name: "John Doe", start: "123.0", end: "75.00", price: "90.00" },
    { id: 4, name: "John Doe", start: "123.0", end: "75.00", price: "90.00" },
    { id: 5, name: "John Doe", start: "123.0", end: "75.00", price: "90.00" },
    // More producers...
];
 */

const producers = producer_reports;

const totalStart = computed(() =>
        store.state.producer_reports.length == 0 ? 0 : store.state.producer_reports
            .map((pr) => pr.startWeight)
            .reduce((sum, x) => sum + x)
);
const totalEnd = computed(() =>
        store.state.producer_reports.length == 0 ? 0 : store.state.producer_reports
            .map((pr) => pr.endWeight)
            .reduce((sum, x) => sum + x)
);

function asPercent(top, bottom) {
    return (100 * (top / bottom)).toFixed(2);
}
</script>

<template>
    <h1 class="flex justify-center text-3xl font-bold underline">
    </h1>

  <div class="px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
          <h1 class="text-xl font-semibold text-gray-900">
            Top Producers for Today
          </h1>
      </div>
    </div>
    <div class="-mx-4 mt-8 flex flex-col sm:-mx-6 md:mx-0">
      <table class="min-w-full divide-y divide-gray-300">
        <thead>
          <tr class="bg-gray-100">
            <th scope="col" class="py-3.5 md:pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0">Employee</th>
            <th scope="col" class="hidden text-right text-sm font-semibold text-gray-900 sm:table-cell">Before Processing</th>
            <th scope="col" class="hidden text-right text-sm font-semibold text-gray-900 sm:table-cell">After Processing</th>
            <th scope="col" class="py-3.5 text-right text-sm font-semibold text-gray-900 sm:pr-4 md:pr-4">Percentage</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(producer, producerIdx) in producers" :key="producer.id" :class="producerIdx % 2 === 0 ? undefined : 'bg-gray-100'">
            <td class="py-4 md:pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
              <div class="font-medium text-gray-900">{{ producer.employeeName }}</div>
              <!-- mobile -->
              <div class="mt-0.5 text-gray-500 sm:hidden">Start: {{ producer.startWeight }} End: {{ producer.endWeight }}</div>
            </td>
            <td class="hidden text-right text-sm text-gray-500 sm:table-cell">{{ producer.startWeight }}g</td>
            <td class="hidden text-right text-sm text-gray-500 sm:table-cell">{{ producer.endWeight }}g</td>
            <td class="py-4 pl-3 pr-4 text-right text-sm text-gray-500 pr-0 sm:pr-4 md:pr-4">{{ asPercent(producer.endWeight,producer.startWeight) }}%</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th scope="row" colspan="1" class="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0">Total</th>
            <th scope="row" class="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden">Total</th>
            <td class="hidden sm:table-cell pt-4 text-right text-sm font-semibold text-gray-900">{{totalStart}}g</td>
            <td class="hidden sm:table-cell pt-4 text-right text-sm font-semibold text-gray-900">{{totalEnd}}g</td>
            <td class="pt-4 text-right text-sm font-semibold text-gray-900 pr-0 sm:pr-4 md:pr-4">
                {{ asPercent(totalEnd,totalStart) }}%
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div class="text-white">
        {{producer_reports}}
    </div>
  </div>
</template>

<style>
</style>
