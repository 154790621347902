// import Vue from "vue";
import Vuex from "vuex";

//Vue.use(Vuex);
// @/store.js
import { createStore } from "vuex";

export default createStore({
  state: {
    mqtt_status: "unstarted",
    stations: [
      {
        name: "Pre-Check",
        code: "precheck",
        clearly_mac: "bcddc2cb4c98",
        weigher_make: "Ishida",
        weigher_model: "IZ-7000",
        weigher_machine_number: "30",
        usage: "precheck",
      },
      {
        name: "Post-Check",
        code: "postcheck",
        clearly_mac: "bcddc2cb4c99",
        weigher_make: "Ishida",
        weigher_model: "IZ-7000",
        weigher_machine_number: "40",
        usage: "precheck",
      },
    ],
    station_huds: [],
    producer_reports: [],
    userData: "USER!",
  },
  mutations: {
    mqtt_status(state, attrs) {
      console.log("vuex mutatte mqtt_status", state, attrs);
      state.mqtt_status = attrs.status;
    },
    StationHud(state, attrs) {
      let ns = Object.assign({}, state.station_huds);
      ns[attrs.info.code] = attrs.info;
      state.station_huds = ns;
    },
    ProducerReports(state, attrs) {
      state.producer_reports = attrs.info.ProducerReports;
    },
  },
  actions: {
    mqtt_pub({ commit, state, dispatch }, attrs) {
      console.log("vs: mqtt_pub", attrs.topic, attrs.message);
      window.mq.publish(attrs.topic, attrs.message);
    },
    mqtt_status({ commit, state, dispatch }, attrs) {
      commit("mqtt_status", attrs);
    },
    updateProducerReports({ commit, state, dispatch }, attrs) {
      commit("ProducerReports", attrs);
    },
    updateStationHud({ commit, state, dispatch }, attrs) {
      // station/%code%/hudvue
      // s = "station/precheck/hudvue"
      let s = attrs.topic;
      let re = "station/(.*)/(.*)";
      let mm = s.match(re);
      let stationCode = mm[1];
      let opCode = mm[2];

      console.log("Station/", stationCode);

      if (opCode == "hudvue") {
        commit("StationHud", attrs);
      } else {
        console.log("vs: mqtt", attrs);
      }
    },
    mqtt({ commit, state, dispatch }, attrs) {
      debugger;
      if (attrs.topic == "precheck_fruit/PfruitUI") {
        commit("PfruitUI", attrs);
      } else {
        console.log("vs: mqtt", attrs);
      }
    },
  },
  getters: {},
});

/*
function baToString(byteArray) {
  let str = new TextDecoder().decode(byteArray);
  return str;
}

function u8aToHexString(uint8arr) {
  if (!uint8arr) {
    return "";
  }

  var hexStr = "";
  for (var i = 0; i < uint8arr.length; i++) {
    var hex = (uint8arr[i] & 0xff).toString(16);
    hex = hex.length === 1 ? "0" + hex : hex;
    hexStr += hex;
  }

  return hexStr.toUpperCase();
}

function u8aFromHexString(str) {
  if (!str) {
    return new Uint8Array();
  }

  var a = [];
  for (var i = 0, len = str.length; i < len; i += 2) {
    a.push(parseInt(str.substr(i, 2), 16));
  }

  return new Uint8Array(a);
}

export default new Vuex.Store({
  state: {
    mqtt_status: "unstarted",
    gpioValues: {},
    antPowerValues: {},
    tagsVisible: {},
    ublipInventory: [],
    uhf_uradar_state: undefined,
    uhf_uradar_states: [],
    uhf_uradar_last_update: undefined,
    ffruit_state: "Error", // factory_fruit state machine state
    ffruit_status: {}, // factory_fruit event status
    cfruit_state: "Error", // collector_fruit state machine state
    cfruit_status: {}, // collector_fruit event status
    pfruit_state: "Error", // precheck_fruit state machine state
    pfruit_status: {}, // precheck_fruit event status
    pfruit_ui: {}, // precheck_fruit main customer UI
    pfruit_mqtt_seen: false, //  set true if pfruit state/status message is seen (so we know we are connected)
    dfruit_state: "Error", // depot_fruit state machine state
    dfruit_status: {}, // depot_fruit event status
    dfruit_barcode: {}, // depot_fruit - request and response of QR Code Scan
    dfruit_operation: {}, // depot_fruit - details of renaming process
  },
  mutations: {
    mqtt_status(state, attrs) {
      console.log("vuex mutatte mqtt_status", state, attrs);
      state.mqtt_status = attrs.status;
    },
    add_tag(state, attrs) {
      let newstate = Object.assign({}, state.tagsVisible);
      // let key = new TextDecoder().decode(attrs.info.getEpc());
      let key = attrs.info.getEpc();
      newstate[key] = attrs.info;

      state.tagsVisible = newstate;
      //state.tagsVisible = [attrs.info];
    },
    CfruitStateMessage(state, attrs) {
      if (attrs.info) {
        if (attrs.info.getState()) {
          state.cfruit_state = attrs.info.getState();
          ///console.log('cfruit_state', state.cfruit_state);
        }
      } else {
        state.cfruit_state = `No attrs.info`;
      }
    },
    CfruitStatus(state, attrs) {
      if (attrs.info) {
        state.cfruit_status = {
          message: attrs.info.getMessage(),
          error: attrs.info.getError(),
        };
      } else {
        state.cfruit_status = { error: "(No Data)" };
      }
    },
    FfruitStateMessage(state, attrs) {
      if (attrs.info) {
        if (attrs.info.getState()) {
          state.ffruit_state = attrs.info.getState();
          ///console.log('ffruit_state', state.ffruit_state);
        }
      } else {
        state.ffruit_state = `No attrs.info`;
      }
    },
    FfruitStatus(state, attrs) {
      if (attrs.info) {
        state.ffruit_status = {
          message: attrs.info.getMessage(),
          error: attrs.info.getError(),
        };
      } else {
        state.ffruit_status = { error: "(No Data)" };
      }
    },
    PfruitStateMessage(state, attrs) {
      if (attrs.info) {
        if (attrs.info.getState()) {
          state.pfruit_state = attrs.info.getState();
          state.pfruit_mqtt_seen = true;
          ///console.log('pfruit_state', state.pfruit_state);
        }
      } else {
        state.pfruit_state = `No attrs.info`;
      }
    },
    PfruitStatus(state, attrs) {
      if (attrs.info) {
        state.pfruit_status = {
          message: attrs.info.getMessage(),
          error: attrs.info.getError(),
        };
        state.pfruit_mqtt_seen = true;
      } else {
        state.pfruit_status = { error: "(No Data)" };
      }
    },
    PfruitUI(state, attrs) {
      if (attrs.info) {
        state.pfruit_ui = attrs.info.toObject();
        state.pfruit_mqtt_seen = true;
        console.log("pfruit_ui update: ", state.pfruit_ui);
      } else {
        state.pfruit_ui = { error: "(No Data)" };
      }
    },
    DfruitStateMessage(state, attrs) {
      if (attrs.info) {
        if (attrs.info.getState()) {
          state.dfruit_state = attrs.info.getState();
          ///console.log('dfruit_state', state.dfruit_state);
        }
      } else {
        state.dfruit_state = `No attrs.info`;
      }
    },
    DfruitStatus(state, attrs) {
      if (attrs.info) {
        state.dfruit_status = {
          message: attrs.info.getMessage(),
          error: attrs.info.getError(),
        };
      } else {
        state.dfruit_status = { error: "(No Data)" };
      }
    },
    DfruitBarcode(state, attrs) {
      if (attrs.info) {
        state.dfruit_barcode = attrs.info;
      } else {
        state.dfruit_barcode = { error: "(No Data)" };
      }
    },
    DfruitOperation(state, attrs) {
      if (attrs.info) {
        state.dfruit_operation = attrs.info;
      } else {
        state.dfruit_operation = { error: "(No Data)" };
      }
    },
    uhf_uradar_state(state, attrs) {
      if (attrs.info) {
        if (attrs.info.getMessage()) {
          // console.log('URADAR STATE', state.uhf_uradar_state);
          state.uhf_uradar_state = attrs.info.getMessage();
          let dateobj = new Date();
          state.uhf_uradar_last_update = dateobj.toISOString();

          if (!state.uhf_uradar_states.includes(state.uhf_uradar_state)) {
            let ns = [...state.uhf_uradar_states, state.uhf_uradar_state];
            state.uhf_uradar_states = ns;
          }
        } else {
          // console.log( 'URADAR STATE', 'ANT', attrs.info.getAntennaNo(), attrs.info.getPower(),);
          // state.uhf_uradar_state = `Antenna ${attrs.info.getAntennaNo()} / Power ${attrs.info.getPower()}`;
          let ns = Object.assign({}, state.antPowerValues);
          ns[attrs.info.getAntennaNo()] = attrs.info.getPower();
          state.antPowerValues = ns;
        }
      } else {
        state.uhf_uradar_state = `No attrs.info`;
      }
    },
    gpio_write_response(state, attrs) {
      let ns = Object.assign({}, state.gpioValues);
      ns[attrs.info.getPin()] = attrs.info.getValue();
      state.gpioValues = ns;
    },
    gpio_read(state, attrs) {
      let ns = Object.assign({}, state.gpioValues);
      ns[attrs.info.getPin()] = attrs.info.getValue();
      state.gpioValues = ns;
    },

    UBlipReadResponse(state, attrs) {
      if (!attrs.info.getSuccess()) {
        console.log("UBlipReadResponse", "ERROR", attrs.info.getMessage());
      } else {
        console.log(
          "UBlipReadResponse",
          "OK",
          attrs.info.getEpc(),
          attrs.info.getBank(),
          attrs.info.getData()
        );
        let epcString = attrs.info.getEpc();
        //let epcString = u8aToHexString(attrs.info.getEpc());
        let nh = Object.assign({}, state.ublipInventory);
        let ub = nh[epcString];
        if (!ub) {
          ub = {};
        }
        let ubkey = `bank${attrs.info.getBank()}`;
        ub[ubkey] = attrs.info.getData();
        nh[epcString] = ub;
        state.ublipInventory = nh;
      }
    },
    UBlipWriteResponse(state, attrs) {
      if (!attrs.info.getSuccess()) {
        console.log("UBlipWriteResponse", "ERROR", attrs.info.getMessage());
      } else {
        console.log("UBlipWriteResponse", "OK", attrs.info.getEpc());
      }
    },
    UBlipLockResponse(state, attrs) {
      if (!attrs.info.getSuccess()) {
        console.log("UBlipLockResponse", "ERROR", attrs.info.getMessage());
      } else {
        console.log("UBlipLockResponse", "OK", attrs.info.getEpc());
      }
    },
  },
  actions: {
    uhf_uradar_event({ commit, state, dispatch }, attrs) {
      commit("add_tag", { info: attrs.info });
    },
    mqtt_pub({ commit, state, dispatch }, attrs) {
      console.log("vs: mqtt_pub", attrs.topic, attrs.message);
      window.mq.publish(attrs.topic, attrs.message);
    },
    mqtt_status({ commit, state, dispatch }, attrs) {
      commit("mqtt_status", attrs);
    },
    mqtt({ commit, state, dispatch }, attrs) {
      if (attrs.topic == "uhf_uradar/state") {
        commit("uhf_uradar_state", attrs);
      } else if (attrs.topic == "uhf_uradar/gpio_read") {
        commit("gpio_read", attrs);
      } else if (attrs.topic == "uhf_uradar/gpio_write_response") {
        commit("gpio_write_response", attrs);
      } else if (attrs.topic == "uhf_uradar/UBlipLockResponse") {
        commit("UBlipLockResponse", attrs);
      } else if (attrs.topic == "uhf_uradar/UBlipReadResponse") {
        commit("UBlipReadResponse", attrs);
      } else if (attrs.topic == "uhf_uradar/UBlipWriteResponse") {
        commit("UBlipWriteResponse", attrs);
      } else if (attrs.topic == "depot_fruit/DfruitStateMessage") {
        commit("DfruitStateMessage", attrs);
      } else if (attrs.topic == "depot_fruit/DfruitStatus") {
        commit("DfruitStatus", attrs);
      } else if (attrs.topic == "depot_fruit/DfruitBarcode") {
        commit("DfruitBarcode", attrs);
      } else if (attrs.topic == "depot_fruit/DfruitOperation") {
        commit("DfruitOperation", attrs);
      } else if (attrs.topic == "factory_fruit/FfruitStateMessage") {
        commit("FfruitStateMessage", attrs);
      } else if (attrs.topic == "factory_fruit/FfruitStatus") {
        commit("FfruitStatus", attrs);
      } else if (attrs.topic == "collector_fruit/CfruitStateMessage") {
        commit("CfruitStateMessage", attrs);
      } else if (attrs.topic == "collector_fruit/CfruitStatus") {
        commit("CfruitStatus", attrs);
      } else if (attrs.topic == "precheck_fruit/PfruitStateMessage") {
        commit("PfruitStateMessage", attrs);
      } else if (attrs.topic == "precheck_fruit/PfruitStatus") {
        commit("PfruitStatus", attrs);
      } else if (attrs.topic == "precheck_fruit/PfruitUI") {
        commit("PfruitUI", attrs);
      } else {
        console.log("vs: mqtt", attrs);
      }
    },
  },
  modules: {},
});
*/
