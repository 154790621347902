import mqtt from "mqtt";

import { hudvue } from "./bundle.js";
let message = hudvue.v1.Hud.create({ guidance: "hello" });
console.log(`message = ${JSON.stringify(message)}`);

// var protobuf = require("protobufjs/minimal");

/*
import { parse, load } from "protobufjs";
parse("hudvue.proto", function(err, root) {
  if (err)
    throw err;

  // example code
  const Hud = root.lookupType("hudvue.v1.Hud")

  let message = Hud.create({ guidance: "hello" });
  console.log(`message = ${JSON.stringify(message)}`);

  let buffer = AwesomeMessage.encode(message).finish();
  console.log(`buffer = ${Array.prototype.toString.call(buffer)}`);

  let decoded = AwesomeMessage.decode(buffer);
  console.log(`decoded = ${JSON.stringify(decoded)}`);
});
*/

// TODO: To abstract and allow mqtt server switching, must create new lient and attach to Vue store somehow
//
// https://www.npmjs.com/package/mqtt
// Additionally, typical TLS errors like ECONNREFUSED, ECONNRESET have been added to a list of TLS errors that will be emitted from the MQTT.js client, and so can be handled as connection errors.

const clientId =
  "mqttjs_" +
  Math.random()
    .toString(16)
    .substr(2, 8);

export default {
  // The install method will be called with the Vue constructor as the first argument, along with possible options
  install(Vue, plug_options) {
    // ES6 way of const job = options.job
    const { uri, store, options } = plug_options;
    // var client = mqtt.connect(`wss://mqtt.taptags.com:9091/mqtt`, options);
    //var client = mqtt.connect(`wss://mqtt.mltaptags.com:9091/mqtt`, options);
    //var client = mqtt.connect(`wss://10.10.0.10:9091/mqtt`, options);
    //
    //var wwurl = new URL(`ws://hudvue:hudvue!@127.0.0.1:8083/mqtt`);
    var wwurl = new URL(uri);
    window.wwurl = wwurl;
    const mqtt_options = {
      protocol: wwurl.protocol.replace(":", ""),
      username: wwurl.username,
      password: wwurl.password,
      hostname: wwurl.hostname,
      port: wwurl.port,
      keepalive: 30,
      clientId: options.clientId,
      protocolId: "MQTT",
      protocolVersion: 4,
      clean: true,
      reconnectPeriod: 1000,
      connectTimeout: 30 * 1000,
      will: {
        topic: "WillMsg",
        payload: "Connection Closed abnormally..!",
        qos: 0,
        retain: false,
      },
      rejectUnauthorized: false,
    };
    var client = mqtt.connect(
      undefined,
      mqtt_options
    );

    console.log("mq client", client);
    client.on("reconnect", function() {
      store.dispatch("mqtt_status", { status: "reconnecting" });
      console.log("reconnecting");
    });
    client.on("connect", function() {
      console.log("connected");
      store.dispatch("mqtt_status", { status: "connected" });
      var topic_list = [
        //    `webapp/${options.clientId}/#`,
        //   `webapp/reader`,
        //  `reader/announce/#`,
        // 'new_scans',
        "hud/#",
        "station/#",
        "test/#",
      ];
      console.log("subscribing", topic_list);
      client.subscribe(topic_list);
    });
    client.on("error", function(error) {
      console.log("Can't connect" + error);
    });

    client.on("message", function(topic, message, packet) {
      let signed_message = false;
      // Allow legacy non-signed messages in; otherwise process after sign verification
      let re = /^station\/.*\/hudvue/;
      if (topic.match(re)) {
        let info = hudvue.v1.Hud.decode(message);
        store.dispatch("updateStationHud", { topic: topic, info: info });
      }

      let hubre = /^hud\/ProducerReports/;
      if (topic.match(hubre)) {
        let info = hudvue.v1.ProducerReports.decode(message);
        store.dispatch("updateProducerReports", { topic: topic, info: info });
        console.log("info is " + info);
        console.log("topic is " + topic);
      }
      /*
        else {
        console.log("message is " + message);
        console.log("topic is " + topic);
      }
      */
      console.log("topic is " + topic);
    });

    //store.dispatch("setMqtt", client);
    //debugger;
    if (store.state.browser_code == "") {
      store.dispatch("setBrowserCode", options.clientId);
    }

    //Vue.prototype.$mqtt = client;
    window.mq = client;

    // Publish example:  -- works
    // window.mq.publish("depot_fruit/DfruitBarcode", "test", {"qos": 0, "retain": 0}, function() { console.log("publish fihished") })

    // Add $plugin instance method directly to Vue components
    // Vue.prototype.$myInfo = (name, age) => info(name, age, job);

    // Add $surname instance property directly to Vue components
    //Vue.prototype.$mqtt = client;
  },
};
