import { createWebHistory, createRouter } from "vue-router";
//import List from './views/List.vue';
//import Home from './views/Home.vue';
//import Detail from './views/Detail.vue';

// 1. Define route components.
// This starter template is using Vue 3 <script setup> SFCs
// Check out https://v3.vuejs.org/api/sfc-script-setup.html#sfc-script-setup
import HelloWorld from "./components/HelloWorld.vue";
import About from "./views/About.vue";
import SomeDetail from "./views/SomeDetail.vue";
import SomeForm from "./views/SomeForm.vue";
import RealTime from "./views/RealTime.vue";
import Analytics from "./views/Analytics.vue";
//const Home = { template: '<div>Home</div>' }
//const About = { template: '<div>About</div>' }

// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
const routes = [
  // { path: "/", component: HelloWorld, props: { msg: "Good Morning" } },
  { path: "/", redirect: "/realtime" },
  { path: "/realtime/:id", component: RealTime },
  { path: "/analytics", component: Analytics },
  { path: "/about", component: About },
  { path: "/somedetail/:id", component: SomeDetail },
  { path: "/someform", component: SomeForm },
  //{ path: '/home', component: Home },
  //{ path: "/list", component: List, },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
