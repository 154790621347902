import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import { Buffer } from 'buffer';
import Process from 'process';
globalThis.process = Process;
globalThis.Buffer = Buffer;
window.process = Process;
//window.Buffer = window.Buffer || require("buffer").Buffer;
window.process = {
   env: { DEBUG: undefined },
   nextTick: function() {
     return null;
   }
};




import App from "./App.vue";
import router from "./router.js";
import "./index.css";

import store from "./store/";
import tapMqtt from "./tapMqtt";

// Set up MQTT Client
function generate_random_string(string_length) {
  let random_string = "";
  let random_ascii;
  for (let i = 0; i < string_length; i++) {
    random_ascii = Math.floor(Math.random() * 25 + 97);
    random_string += String.fromCharCode(random_ascii);
  }
  console.log("client id", random_string);
  return random_string;
}

var mqtt_options = {
  clientId: generate_random_string(8),
  //username: process.env.VUE_APP_TAPTAGS_MQTT_USERNAME,
  //password: process.env.VUE_APP_TAPTAGS_MQTT_PASSWORD,
  clean: true,
};

// 5. Create and mount the root instance.
const app = createApp(App);
// Make sure to _use_ the router instance to make the
// whole app router-aware.
app.use(router);
app.use(store);
console.log("mqtt_url", import.meta.env.VITE_APP_TAPTAGS_MQTT_URL);
console.log("mqtt_url", import.meta);
app.use(tapMqtt, {
  uri: import.meta.env.VITE_APP_TAPTAGS_MQTT_URL,
  store: store,
  options: mqtt_options,
});
app.mount("#app");

//createApp(App).mount('#app')
