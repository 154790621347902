<template>
    <footer class="bg-white">
        <div
            class="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-9"
        >
            <p class="mt-8 text-center text-base text-gray-400">
                &copy; 2022 TapIt, Inc. All rights reserved.
            </p>
        </div>
    </footer>
</template>
